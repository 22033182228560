<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
          <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="目录标识" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="category_mark">
              <a-input v-model="form.category_mark" placeholder="请输入目录标识..." />
            </a-form-model-item>
          </a-col>
          <!-- <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="ES主机名" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="es_hosts_name">
              <a-input v-model="form.es_hosts_name" placeholder="请输入ES主机名..." />
            </a-form-model-item>
          </a-col> -->

          <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="目录状态" :labelCol='labelcol' :wrapperCol="wrappercol" prop="is_delete">
              <a-select v-model="form.is_delete" placeholder="请选择目录状态..." :options='is_delete'>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
            <a-form-model-item style="text-align:right;">

              <a-button type="primary" @click="search">
                搜索
              </a-button>
              <a-button @click="reset('ruleForm')">重置</a-button>

            </a-form-model-item>
          </a-col>
      </a-form-model>
    </div>
    <div class="mainContent" style="display: flex;justify-content: flex-end;">
      <a-button type="primary" @click="onAdd">
        添加目录
      </a-button>
    </div>
    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title">查询表格</div>
        <div class="operate">

          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start" :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table
        :size="tableSize"
        :scroll="{ x: true }"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :row-key="record => record.id"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        bordered
        @change="handleTableChange"
      >
        <!-- 状态单元格 -->
        <template slot="is_delete" slot-scope="data,record">
          <a-tag :color="record.is_delete=='0'?'green':'red'">
              {{record.is_delete|statusFilter}}
          </a-tag>
        </template>
        <!-- 操作单元格 -->
        <template slot="操作" slot-scope="data">

              <span>

                <a-button size="small" type="primary"  @click="onEdit(data)">编辑</a-button>

                <a-button size="small" type="primary"  @click="onEditWeb(data)">绑定网站</a-button>

                <a-popconfirm v-show="data.is_delete=='0'" title="是否禁用此目录?" @confirm="() => onDisable(data)">
                  <a-button size="small" type="danger">禁用</a-button >
                </a-popconfirm>
                <a-popconfirm v-show="data.is_delete=='1'" title="是否启用此目录?" @confirm="() => onEnable(data)">
                  <a-button size="small" type="primary" class="greenBtn">启用</a-button >
                </a-popconfirm>

              </span>

        </template>
      </a-table>
    </div>

  <!-- 修改帐号信息模态框 -->
  <a-modal
      width='650px'
      :title="title"
      :visible="editModalVisible"
      :confirm-loading="confirmLoading"
      @ok="save"
      @cancel="cancel"
    >

      <!-- 编辑 -->
      <a-form-model
      ref="editRef"
      :model="editForm"
      :rules="editFormRules"
      :label-col="{span:7}"
      :wrapper-col="{span:17}"
      >
      <a-form-model-item label="ES主机" prop="es_hosts_id">
        <a-select v-model="editForm.es_hosts_id" placeholder="请选择主机..." :options='es_hostList'>

        </a-select>
      </a-form-model-item>

      <a-form-model-item label="标识" prop="category_mark">
        <a-input v-model="editForm.category_mark" placeholder="请输入标识..."/>
      </a-form-model-item>

      <a-form-model-item label="状态" prop="is_delete" v-if="title=='编辑模板'">
        <a-select v-model.Number="editForm.is_delete" placeholder="请选择模板状态..." :options='is_delete_edit'>

        </a-select>
      </a-form-model-item>
      </a-form-model>



  </a-modal>

  <!-- 绑定帐号 -->
  <a-modal

      title="绑定网站"
      :visible="editWebModalVisible"
      :confirm-loading="confirmLoading"
      @ok="saveWeb"
      @cancel="cancelWeb"
    >

    <a-table
    :scroll="{ y: 400 }"
      :size="tableSize"
      :row-selection="{ selectedRowKeys: editWebForm.site_ids, onChange: onSelectWebChange }"
      :columns="webColumns"
      :row-key="record => record.id"
      :data-source="webList"
      :pagination="false"
      :loading="loadingWeb"
      bordered
    >
      <!-- 状态单元格 -->
      <template slot="is_bind" slot-scope="data,record">
        <a-tag :color="record.is_bind=='1'?'green':'red'">
            {{record.is_bind=='1'?'已绑定':'未绑定'}}
        </a-tag>
      </template>
    </a-table>





  </a-modal>

  </div>
</template>
<script>
import {getCategoriesList,editCategories,addCategories,getESCategories,categoriesBind,categoriesOperate,getCategoryName} from "@/axios/contents.js"
import {getAllESHostsList} from "@/axios/es_host.js"
import {getAllSites} from "@/axios/web.js"

import {myMixin} from "@/static/js/mixin.js"
import moment from 'moment';

let editWebForm={
  site_category_id:'',
  site_ids:[],
};
const webColumns = [//列描述
  {
    align:'center',
    title: 'ID',
    dataIndex: 'id',
  },
  {
    align:'center',
    title: '域名',
    dataIndex: 'domain',
  },
  {
    align:'center',
    title: '绑定状态',
    dataIndex: 'is_bind',
    scopedSlots: { customRender: 'is_bind', },
  },
];
const columns = [//列描述
  {
    align:'center',
    title: 'ID',
    dataIndex: 'id',
  },
  {
    align:'center',
    title: '目录标识',
    dataIndex: 'category_mark',
  },
  {
    align:'center',
    title: 'ES主机名称',
    dataIndex: 'es_hosts_name',
  },

  {
    align:'center',
    title: '状态',
    dataIndex: 'is_delete',
    scopedSlots: { customRender: 'is_delete', },
  },
  {
    align:'center',
    title: '操作',
    // dataIndex: 'status',
    scopedSlots: { customRender: '操作', },
  },

];
export default {
  filters: {

    statusFilter(status) {
      const statusMap = {
        0: '启用',
        1: '禁用',
      }

      return statusMap[status]

    },
  },
  mixins: [myMixin],
  data() {

    return {
      is_delete_edit: [
        {
            value: 0,
            label: '启用'
        },
        {
            value: 1,
            label: '禁用'
        },
      ],
      is_delete: [
        {
            value: -1,
            label: '全部'
        },
        {
            value: 0,
            label: '启用'
        },
        {
            value: 1,
            label: '禁用'
        },
      ],

      form: {
        page_size: 10,
        page:1,
        category_mark: '',
        // es_hosts_name:'',
        is_delete:0,
        // iso2:''
      },
      treeIsLoad:false,
      columns,//列描述
      title:'添加目录',
      confirmLoading:false,//编辑模态框
      editModalVisible:false,
      editTree:[],
      es_hostList:[],
      editForm:{
        es_hosts_id:'',
        category_ids:[],
        is_delete:-1,
        category_mark:'',
        site_category_id:'',
      },
      editFormRules: {
        category_mark: [
          { required: true, message: '请输入标识', trigger: 'change' },
        ],
        es_hosts_id: [
          { required: true, message: 'ES_ID', trigger: 'change' },
        ],
        category_ids: [
          { required: true, message: '请选择目录', trigger: 'change' },
        ],

      },

      webColumns,
      loadingWeb:true,
      editWebForm:Object.assign({},editWebForm),
      webList:[],
      editWebModalVisible:false,
    }

  },
  mounted() {
    let that=this
    this.fetch();
    getAllESHostsList().then((data)=>{
      that.es_hostList=data.data.map((item)=>{
        return{
          value:item.es_hosts_id,
          label:item.es_hosts_name
        }
      })
      console.log(that.es_hostList,'ES列表')
    });

  },
  watch:{
        // 被侦听的变量count
        editTree(){
          this.$nextTick(function() {
            console.log('渲染完成')
            this.treeIsLoad=false
          });
        }
    },
  methods:{

      onSelectWebChange(selectedRowKeys) {//选择表格
        this.editWebForm.site_ids = selectedRowKeys;
      },

      async fetch (params = {}) {
        this.loading = true;
        let data=await getCategoriesList({...params,...this.form})
        this.loading = false;
        const pagination = { ...this.pagination };
        pagination.total = data.data.total;
        this.data = data.data.list;
        console.log(this.data)
        this.pagination = pagination;
      },


      //禁用目录
      async onDisable(data){

        let resule=await categoriesOperate({is_delete:1,site_category_id:data.id});
          if(resule.status==1){
            this.$message.success(resule.errmsg);
            data.is_delete=1
          }else{
            this.$message.error(resule.errmsg);
        }
      },
      //启用目录
      async onEnable(data){

        console.log(data,'data')
        let resule=await categoriesOperate({is_delete:0,site_category_id:data.id});
          if(resule.status==1){
            this.$message.success(resule.errmsg);
            data.is_delete=0
          }else{
            this.$message.error(resule.errmsg);
        }
      },

      async changeEs_id(value){
        this.treeIsLoad=true

        let res=await getESCategories({es_hosts_id:value})
        if(res.status==0){
          this.editTree=[]
        }else{
          this.editTree=res.data.tree
          this.editForm.category_ids=[]
        }
      },
      //添加用户数据
      onAdd(){
        this.editModalVisible=true
        this.title='添加目录'
      },
      //编辑保存用户数据
      async onEdit(data){
        console.log(data,'编辑传过来的数据')
        this.title='编辑目录'
        this.editModalVisible=true
        this.treeIsLoad=true
        this.$nextTick(()=>{
          getCategoryName({site_category_id:data.id}).then((res)=>{
            this.editTree=res.data.category_name.tree
            this.editForm.category_ids=res.data.category_name.selected
          })

          this.editForm.category_mark=data.category_mark
          this.editForm.es_hosts_id=data.es_id
          this.editForm.site_category_id=data.id
          this.editForm.is_delete=data.is_delete
        })


      },
      async save(){
        console.log(this.editForm,'this.editForm')


        this.$refs.editRef.validate( async valid => {
          if (valid) {
            this.confirmLoading = true;

            let resule
            if(this.title=='添加目录'){
              const {site_category_id,is_delete,...data}=this.editForm
              resule=await addCategories(data);

            }else{
              const data={...this.editForm}
              resule=await editCategories(data);
            }
            console.log(resule,'resule')
            this.confirmLoading = false;
            if(resule.status==1){
            console.log(resule)
            this.$message.success(resule.errmsg);
            this.editModalVisible = false;
            this.$refs.editRef.resetFields();
            this.fetch();
            }else{
              if(resule!='请求超时'){
                this.$message.error(resule.errmsg);
              }
            }
          } else {
            return false;
          }
        });
      },
      cancel(){
        this.editTree=[]
        this.$refs.editRef.resetFields();
        this.editModalVisible = false;
        this.confirmLoading = false;
      },


      //绑定网站
      onEditWeb(data){
        this.editWebModalVisible=true
        this.loadingWeb = true;
        getAllSites({es_hosts_id:data.es_id}).then((res)=>{
          this.loadingWeb = false;
          this.webList=res.data

            this.$nextTick(()=>{
              this.editWebForm.site_ids=this.webList.map((item)=>{
               if(item.is_bind==1) return item.id
              })
            this.editWebForm.site_category_id=data.id
          })
        })

      },
      async saveWeb(){
        console.log(this.editWebForm)
        this.confirmLoading = true;

        let resule=await categoriesBind({...this.editWebForm});

          this.confirmLoading = false;
          if(resule.status==1){

            this.$message.success(resule.errmsg);
            this.editWebModalVisible = false;
            this.editWebForm=Object.assign({},editWebForm);
            this.fetch();
          }else{
            this.$message.error(resule.errmsg);
          }

      },
      cancelWeb(){
        this.editWebForm=Object.assign({},editWebForm);
        this.webList=[];
        this.editWebModalVisible = false;
      },
    }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }
.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}

</style>
