
import {get,post} from "@/axios";

//分组页面
export function getCategoriesList(data) {
    return get('/api/categories/list',data);
}
export function addCategories(data) {
    return post('/api/categories/add',data);
    
}
export function editCategories(data) {
    return post('/api/categories/edit',data);
}


export function getESCategories(data) {
    return get('/api/categories/getESCategories',data);
}

export function categoriesBind(data) {
    return post('/api/categories/bind',data);
}


export function categoriesOperate(data) {
    return post('/api/categories/operate',data);
}
//获取目录名
export function getCategoryName(data) {
    return get('/api/categories/getCategoryName',data);
}